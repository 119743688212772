<template>
  <v-form ref="form" lazy-validation v-model="valid">
    <v-card flat>
      <v-dialog 
      v-model="dialog"
      max-width="290"
      >
      <v-snackbar
        v-model="snackbar"
        :color="actionClass"
        timeout="2000"
      >
        {{ message }}
      </v-snackbar>
        <v-card height='' class="">
          <v-card-title class="d-flex justify-center">
              <v-avatar :color="`${sendySuccessLight}`" > <v-icon class="" :color="`${sendySuccessDark}`"> mdi-check-circle-outline </v-icon> </v-avatar>
          </v-card-title>
          <div class="d-flex justify-center"><span class="text-caption">{{ message }} </span></div>
        <v-card-text class="">
          <div class=""> <span class="text-center text-h6">Unique Id : {{ response.id }} </span></div>
          <div><span class="text-center text-h6">Name : {{ response.name }}</span> </div>
          <div><span class="text-center text-h6">Residence : {{ response.residence }}</span></div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :color="sendyAzureTransporter30" dark class="mt-2" @click="dialog=false">Ok</v-btn>
        </v-card-actions>
      </v-card>
      </v-dialog>

      <v-card-text>
        <v-text-field
        label="Enter Name"
        outlined
        placeholder="Enter full Name"
        class=""
        v-model="name"
        :rules= "[v => !!v || 'Name is required']"
        >
        </v-text-field>

        <v-text-field
        label="Age"
        outlined
        placeholder="age"
        class=""
        v-model="age"
        :rules="rules.age"
        >
        </v-text-field>

        <v-text-field
        label="Next of Keen"
        outlined
        placeholder="Next of Keen"
        class=""
        v-model="next_of_keen"
        >
        </v-text-field>
        
        <v-text-field
        label="ID No"
        outlined
        placeholder="Id number"
        class=""
        :rules="rules.id_number"
        v-model="id_number"
        >
        </v-text-field>
        <vue-tel-input-vuetify
        v-model="phone"
        label="Mobile"
        outlined
        placeholder="Mobile"
        class=""
        required
        @input="onInput"
        :error-messages="errors"
        >
        </vue-tel-input-vuetify>

        <v-text-field
        label="Residence"
        outlined
        placeholder=""
        class=""
        v-model="residence"
        :rules= "[v => !!v || 'Residence is required']"
        >
        </v-text-field>
        
        <v-text-field
        label="Temperature"
        outlined
        placeholder=""
        class=""
        v-model="temperature"
        :rules="rules.temperature"
        >
        </v-text-field>

        <v-btn :color="sendyMidnightBlue20" dark shaped block large @click="submitForm" :loading="loading"> Submit </v-btn>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { mapActions } from 'vuex';
import colorMxn from '@/mixins/color_mixins';

export default {
  name: "newEntry",
  mixins: [colorMxn],
  data() {
    return {
      dialog: false,
      loading: false,
      name: '',
      age: '',
      next_of_keen: '',
      id_number: '',
      phone: '',
      residence: '',
      temperature: '',
      message: "Member saved successfully",
      response: {},
      valid: true,
      snackbar: false,
      actionClass: '',
      rules: {
        required: value => !!value || 'Required.',
        temperature: [
          v => v.length <= 2  || 'Max of 2 digits required',
          v => !!v || 'Temperature is required',
          v => /^\d+$/.test(v) || "Only digits are required"
        ],
        id_number: [
          v => v.length <= 8 || 'Max of 8 digits required',
          v => /^\d+$/.test(v) || "Only digits are required",
        ],
        age: [
          v => !!v || 'Age is required',
          v => /^\d+$/.test(v) || "Only digits are required",
        ],
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
      phoneV: '',
      errors: '',
      country: '',
      formattedNumber: '',
      number: '',
    }
  },
  watch: {
    dialog(val) {
      if (!val ) {
        this.$refs.form.reset();
      }
    },
  },
  methods: {
    ...mapActions(['performPostActions']),
    onInput(formattedNumber, { number, valid, country }) {
      this.errors = valid ? '': "Invalid Phone Number";
      this.formattedNumber = formattedNumber;
      this.country = country;
      this.number = number;

    },
    async submitForm() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true
      const payload = {
        unique_id: this.unique_id,
        name: this.name,
        age: this.age,
        next_of_keen: this.next_of_keen,
        id_number: this.id_number,
        phone: this.phone,
        residence: this.residence,
        temperature: this.temperature,
      };

      const fullPayload = {
        params: payload,
        endpoint: "/register-member"
      };

      const response = await this.performPostActions(fullPayload);
      console.log(response);
      this.actionClass = response.status === 'success' ? 'success' : 'error';
      this.message = response.message;
      this.snackbar = true;
      this.response = response.data;
      this.dialog = response.status === 'success';
      this.loading = false;
    }

  }
}
</script>